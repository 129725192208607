.banner {
	background-size: cover;
	background-position: center center;
	position: relative;
	overflow: hidden;
	video {
		position: absolute;
		top: 50%;
		left: 0;
		min-width: 100%;
		transform: translateY(-50%);
	}

	.container {
		.ratio(.8);
		position: relative;
		text-align: center;
		font-size: 0;
		z-index: 2;
	}

	.content {
		display: inline-block;
		vertical-align: middle;
		font-size: 1rem;

		h1 {
			.paytone(66);
			margin-top: 0;
			color: white;
			text-transform: uppercase;
			line-height: 1em;
			font-weight: 400;

			strong {
				color: @yellow;
				font-weight: 400;
			}
		}

		h2 {
			.paytone(26);
			color: white;
			text-transform: uppercase;
			line-height: 1.2em;
			font-weight: 400;
		}
	}
}

.page-header {
	background-size: cover;
	position: relative;
	overflow: hidden;
	.background-mask {
		video {
			position: absolute;
			top: 50%;
			left: 0;
			min-width: 100%;
			transform: translateY(-50%);
		}
	}

	.container {
		position: relative;
		z-index: 2;
		.ratio(0.312571429);
		font-size: 0;
		text-align: center;
		
		.inner {
			display: inline-block;
			vertical-align: middle;
		}

		h1 {
			.paytone(54);
			display: inline-block;
			vertical-align: middle;
			color: white;
			margin-top: 0;
			margin-bottom: 0;
			text-transform: uppercase;
		}
		h2 {
			color: white;
			margin-top: 0;
			margin-bottom: 0;
			.bree(18);
		}
	}
	.has-page-video & {
		background-image: none !important;
		background-color: #777777;
	}
}

.std-txt {
	h1 {
		.paytone(46);
		margin-bottom: 0;
		color: @brown;

		strong {
			color: @yellow;
		}
		.big-phone-and-smaller({
			.paytone(30);
		})
	}

	h2 {
		.paytone(25);
		color: @yellow;
		margin-bottom: 0;
	}

	h1+h2 {
		margin-top: 0;
	}
	
	h3 {
		.bree(25);
		
		&.yellow {
			color: @yellow;
		}
	}

	p {
		.bree(21);
		color: @brown;
		margin-top: .5em;
		margin-bottom: .5em;

		a {
			color: @green-light;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}
	a {
		color: @yellow;
	}
	
	span {
		&.green {
			color: @green;
		}
		&.brown {
			color: @brown;
		}
		&.yellow {
			color: @yellow;
		}
	}
	
	strong em, em strong {
		font-style: normal;
		font-weight: 600;
		color: @yellow;
	}
	
	ul, ol {
		li {
			.bree(21);
		}
	}
	ul {
		list-style: none;
		padding-left: 20px;
		li::before {
			content: "•"; 
			color: @green; 
			display: inline-block; 
			width: 1em;
			margin-left: -1em
		}
		
		&.yellow-chevron {
			li::before {
				content: url('/img/chevron-right-yellow.png');
			}
		}
	}
	ol {
		list-style: none;
		counter-reset: li;
		& > li {
			&::before {
				content: counter(li) + '.'; 
				color: @green; 
				display: inline-block; 
				width: 1em;
				margin-left: -1em;
				counter-increment: li;
			}
		}
	}
	
	hr {
		border-color: fade(@grey-body-text, 15%);
		border-width: 2px;
	}
	
	&.grey {
		a {
			color: @yellow;
		}
		ul {
			list-style: none;
			li::before {
				color: @yellow; 
			}
		}
		ol {
			& > li {
				&::before {
					color: @yellow; 
				}
			}
		}
	}
}


.page-container {
	&.ring-pattern {
		overflow: hidden;

		.container {
			position: relative;

			&:before {
				content: '\00a0';
				position: absolute;
				right: 100%;
				top: -50px;
				bottom: -50px;
				width: 1000px;
				background-position: right top;
				background-repeat: repeat-y;
				background-image: url("@{cdn1}/img/ring-pattern-left.png");

				.webp & {
					background-image: url("@{cdn1}/img/ring-pattern-left.webp");
				}

				@media (max-width: 264px) {

					background-image: url("@{cdn1}/img/ring-pattern-left-half.png");

					.webp & {
						background-image: url("@{cdn1}/img/ring-pattern-left-half.webp");
					}
				}

				@media (-webkit-min-device-pixel-ratio: 1.5),
				(min-resolution: 144dpi) {
					background-image: url("@{cdn1}/img/ring-pattern-left-2x.png");

					.webp & {
						background-image: url("@{cdn1}/img/ring-pattern-left-2x.webp");
					}

					@media (max-width: 264px) {

						background-image: url("@{cdn1}/img/ring-pattern-left.png");

						.webp & {
							background-image: url("@{cdn1}/img/ring-pattern-left.webp");
						}
					}
				}
			}

			&:after {
				content: '\00a0';
				position: absolute;
				left: 100%;
				top: -50px;
				bottom: -50px;
				width: 1000px;
				background-position: left top;
				background-image: url("@{cdn2}/img/ring-pattern-right.png");
				background-repeat: repeat-y;

				.webp & {
					background-image: url("@{cdn2}/img/ring-pattern-right.webp");
				}

				@media (max-width: 257px) {

					background-image: url("@{cdn2}/img/ring-pattern-right-half.png");

					.webp & {
						background-image: url("@{cdn2}/img/ring-pattern-right-half.webp");
					}
				}

				@media (-webkit-min-device-pixel-ratio: 1.5),
				(min-resolution: 144dpi) {
					background-image: url("@{cdn2}/img/ring-pattern-right-2x.png");

					.webp & {
						background-image: url("@{cdn2}/img/ring-pattern-right-2x.webp");
					}

					@media (max-width: 257px) {

						background-image: url("@{cdn2}/img/ring-pattern-right.png");

						.webp & {
							background-image: url("@{cdn2}/img/ring-pattern-right.webp");
						}
					}
				}
			}
		}
	}

	&.grey {
		.ready-to-load-img.stone-pocked-pattern-picture-background();
		.container {
			padding-top: 20px;
			padding-bottom: 50px;
		}
		background: @grey-page-section-back;
		background-position: center center;

	}
	&.purple {
		.container {
			padding-top: 20px;
			padding-bottom: 50px;
		}
		background: @purple-light;
		
		h2, p, li, div {
			color: white;
		}

	}
	&.no-pad {
		padding-top: 0;
		padding-bottom: 0;
	}
	&.white {
		background-position: bottom center;
		background-repeat: repeat-x;
	}
}

.expando {
	@padding-spec: 25px;

	.opener {
		text-transform: uppercase;
		cursor: pointer;
		padding: 15px 25px;
		background-color: @grey-page-section-back;

		&:after {
			content: url('/img/chevron-down-yellow.png');
			float: right;
			transition: transform .3s;
		}
	}

	&.open {
		& > .opener:after {
			transform: rotate(180deg);
		}
	}

	.view {
		max-height: 0;
		overflow: hidden;
		transition: max-height .3s;

		.content {
			padding: 0 @padding-spec @padding-spec;
		}
	}

	.editing-page & {
		.view {
			max-height: none;
		}
	}
}

.blue-button {
	.paytone(17);
	display: inline-block;
	border: 0;
	padding: 10px 17px;
	background-color: @blue;
	color: white !important;
	text-transform: uppercase;
	text-decoration: none;
	transform: background-color .3s;

	&:hover {
		background-color: @blue-dark;
		text-decoration: none !important;
	}


}

.green-button {
	.paytone(17);
	display: inline-block;
	border: 0;
	padding: 10px 17px;
	background-color: @green-light;
	color: white !important;
	text-transform: uppercase;
	text-decoration: none;
	transform: background-color .3s;

	&:hover {
		background-color: @green;
		text-decoration: none !important;
	}

	img {
		padding-right: 15px;
		vertical-align: middle;
	}

}

.inner-header {
	background-color: @brown;
	color: white;
	padding-top: 20px;
	position: relative;
	z-index: 20;

	a {
		color: white;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
	.logo {
		text-align: center;
		.candal(24);

		.icon, .txt, .icon-50 {
			display: inline-block;
			vertical-align: middle;
		}

		.txt {
			text-align: left;
			padding-left: 10px;

			strong {
				color: @green-light;
			}
		}
		
		.icon-50 {
			padding-left: 20px;
			img {
				height: 100px;
			}
		}
	}

	.tablet({
		.top {
			display: grid;
			grid-template-columns: 1fr 2fr 1fr auto;
			align-items: center;

			& > .contact {
				.bree(13);
				display: grid;
				grid-template-columns: auto 1fr;
				column-gap: 20px;

				.txt {
					text-align: right;
				}

				.icon {
					align-self: center;

					img {
						display: block;
					}
				}

			}
			.actions {
				text-align: right;
				
				.sep-links {
					margin-top: 15px;
				}
			}
			.icon-50 {
				padding-left: 10px;
				img {
					// height: 75px;
				}
			}
		}
		nav {
			.close {
				display: none;
			}
			.menu {
				padding: 0;
				margin: 0;
				list-style: none;
				display: flex;
				justify-content: space-around;
				& > li {
					& > a {
						.paytone(17);
						text-transform: uppercase;
						&:hover {
							text-decoration: none;
						}
					}
				}
				& > li {
					position: relative;
					padding-top: 10px;
					padding-bottom: 20px;
					ul {
						display: block;
						position: absolute;
						top: 100%;
						left: 0;
						list-style: none;
						width: 200px;
						margin: 0;
						padding: 0;
						margin-left: -10000px;
						opacity: 0;
						transition: opacity .3s;

						li {
							display: block;
							background-color: @green-light;
							color: white;
							
							a {
								.bree(17);
								display: block;
								color: white;
								text-decoration: none;
								padding: 15px;
							}
							
							&:hover {
								background-color: @green;
							}
							
						}
					}
					
					&:hover {
						ul {
							opacity: 1;
							margin-left: 0;
						}
					}
				}
			}
		}
	});

	.tablet-and-smaller({
		
		padding-left: 0;
		padding-right: 0;
		.top {
			.contact, .icon-50 {
				display: none;
			}
			.actions {
				display: grid;
				grid-template-columns: 1fr 1fr;
				column-gap: 41px;
				text-align: center;
				background-color: @brown-light;
				padding-top: 10px;
				padding-bottom: 10px;
				
				.contact, .bar {
					display: none;
				}
				
				.sep-links {
					display: contents;
				}
				
				.donate {
					grid-row: 1;
					grid-column: 2;
					justify-self: left; 
					width: 6em;
				}
				.calendar {
					.green-button;
					grid-row: 1;
					grid-column: 1;
					justify-self: right;
					width: 6em;
				}
				
				
			}
			.logo {
				// text-align: left;
				padding-bottom: 20px;
			}
		}
		nav {
			position: fixed;
			width: 100%;
			width: 100vw;
			height: 100%;
			height: 100vh;
			z-index: 5;
			top: 0;
			left: -20000px;
			max-width: 350px;
			opacity: 0;
			transition: opacity .3s;
			overflow: auto;
			padding: 50px 15px 0 25px;
			box-sizing: border-box;
			background-color: @green;
			
			.close {
				font-size: 30px;
				position: absolute;
				top: 20px;
				right: 20px;
			}


			.menu {
				padding: 0;
			}
			ul {
				list-style: none;
			}

			/*
			& > li {
				display: block;
				text-align: center;

				& > a {
					text-align: center;
					font-weight: 600;
				}

				& > ul {
					position: relative;
					left: auto;
					opacity: 1;
					text-align: center;

					a {
						text-align: center;
					}
				}
			}
			*/
			ul ul {
				padding-left: 10px;
			}
			

			&.open {
				right: 0;
				opacity: 1;
				left: auto;
			}
			
			& > ul > li > a {
				.paytone(22);
			}
			& > ul > li {
				margin-bottom: 15px;
			}
		}

		.tribar {
			width: 30px;
			margin: 10px 10px 0 0;
			cursor: pointer;
			position: absolute;
			top: 10px;
			right: 10px;

			.one, .two, .three {
				border-bottom: solid 3px;
				border-color: white;
			}

			.two {
				margin: 7px 0;
			}

		}
	});
	
	.phone-and-smaller({
		.top {
			.logo {
				.icon {
					img {
						height: 50px;
						width: auto;
					}
				}
				.icon-50 {
					img {
						height: 55px;
					}
				}
				.txt {
					.candal(14);
				}
			}
		}

	});
}

.landing-box {
	display: grid;
	background-color: @brown;
	margin-top: 30px;
	margin-bottom: 30px;
	
	.pic {
		background-size: cover;
		.ratio(1);
	}
	.content {
		padding-left: 50px;
		h2 {
			color: white;
			.paytone(44);
			border-bottom: solid 5px;
			text-transform: uppercase;
			padding-bottom: 10px;
			font-weight: 400;
			line-height: 1.1em;
		}
		h3 {
			color: white;
			.paytone(26);
		}
		p {
			color: white;
			.bree(21);
		}
		h2, h3, p {
			padding-right: 20px;
		}
	}
	.link {
		background-color: @brown-light;
		text-align: center;
	}
	
	html:not(.editing-page) &[data-link]:not([data-link=""]) {
		cursor: pointer;
	}
	
	&.pic-right, &.pic-left {
		.link {
			.link-text {
				display: none;
			}
		}
	}
	
	&.facility-rental {
		display: grid;
		
		.link {
			display: none;
		}

		.tablet({
			grid-template-columns: 2fr 1fr;

			.pic {
				grid-row: 2;
				grid-column: 2;
			}
			.content {
				display: contents;
				h2 {
					grid-row: 1;
					grid-column: 1 / span 2;
					border-bottom: 0;
					margin-top: 0;
					padding: @page-side-padding;
				}
				.below {
					grid-row: 2;
					grid-column: 1;
					background-color: @grey-page-section-back;
					padding: 0 @page-side-padding 20px @page-side-padding;
					color: @grey-body-text;
					h2, h3, p {
						color: @grey-body-text;
					}
				}
			}
		});
		.tablet-and-smaller({
			grid-template-columns: 1fr;
			.content {
				padding: 0;
				grid-column: 1;
				h2 {
					padding-left: 20px;
				}
				.below {
					padding: 20px;
					background-color: @grey-page-section-back;
					
					h3, p {
						color: @grey-body-text;
					}
				}
			}
			.pic {
				grid-row: 2;
			}
		});
		

	}
	&.camps-pic-right {
		.content {
			h2 {
				border-bottom: 0;
				.paytone(35);
				small {
					.paytone(30);
				}
			}
		}
		.link {
			margin-left: 50px;
			margin-bottom: 20px;
			text-decoration: none;
			color: white;
			padding-top: 5px;
			padding-bottom: 9px;
			text-transform: uppercase;
			padding-left: 12px;
			padding-right: 12px;
			img {
				display: none;
			}
		}
		
		&.no-button {
			.link {
				display: none;
			}
		}
	}
	&.visit-pic-right {
		.content {
			padding-right: 20px;
			h2 {
				border-bottom: 0;
				.paytone(40);
				small {
					.paytone(30);
				}
			}
		}
		.link {
			display: none;
		}
		
		.big-phone({
			grid-template-columns: 8fr 4fr;

			.content {
				grid-column: 1;
				grid-row: 1;
			}
			
			.pic {
				grid-column: 2;
				grid-row: 1;
				max-height: 400px;
			}
		});
		
		.big-phone-and-smaller({
			grid-template-columns: 1fr;
			.pic {
				.ratio(1.5);
			}
		});
		
	}
	&.wild-camps-pic-right {
		.link {
			.link-text {
				display: none;
			}
			padding-left: 10px;
			padding-right: 10px;
		}

		.tablet ({
			grid-template-columns: repeat(24, 1fr);
			.pic {
				.ratio(.75);
				grid-row: 1;
				grid-column: 10 / span 16;
			}
			.link {
				grid-row: 1;
				grid-column: 1;
				align-self: initial;
				display: flex;
				align-items: center;
			}
			.content {
				grid-row: 1;
				grid-column: 2 / span 18;
				padding-right: 20%;
			}

		});
		.tablet-and-smaller({
			grid-template-columns: 1fr;
			.content {
				padding: 20px;
			}
			.pic {
				.ratio(.75);
				grid-row: 2;
			}
			.link {
				grid-row: 3;
				padding-top: 10px;
				padding-bottom: 10px;
			}

		})
	}
	&.wild-camps-pic-left {
		grid-template-columns: repeat(24, 1fr);
		.pic {
			.ratio(.75);
			grid-row: 1;
			grid-column: 1 / span 16;
		}
		.link {
			display: flex;
			display: none;
			grid-row: 1;
			grid-column: 1;
			align-self: initial;
			align-items: center;
			.link-text {
				display: none;
			}
			padding-left: 10px;
			padding-right: 10px;
		}
		.content {
			grid-row: 1;
			grid-column: 12 / span 13;
			padding-left: 30px;
			padding-right: 30px;
			display: flex;
			align-items: center;
			h2 {
				padding-right: 0;
			}
			.below {
				display: none;
			}
		}
		.tablet-and-smaller({
			// grid-template-columns: 1fr;
			display: block;
			.content {
				padding: 20px;
			}
			.pic {
				.ratio(.75);
				grid-row: 2;
			}
			.link {
				grid-row: 3;
				padding-top: 10px;
				padding-bottom: 10px;
			}

		})
	}
	
	&.pic-with-caption {
		.pic {
			.ratio(1.1);
		}
		.content {
			padding-right: 20px;
			// padding-left: 0;
			padding-left: 0px;
			padding-top: 0px;
			padding-bottom: 20px;
			.std-txt {
				h1 {
					margin-top: 0
				}
				h2 {
					.paytone(25);
					color: @yellow;
					border-bottom: 0;
				}
			}
			
			.diff-text {
				h2 {
					.bree(21);
					border-bottom: 0;
					text-transform: none;
					font-weight: 600;
					margin-top: 0;
				}
				p {
					.bree(21);
					font-weight: 300;
				}
			}
		}
		.link {
			display: contents;
			img {
				display: none;
			}
			.link-text {
				.paytone(17);
				align-self: start;
				color: white;
				background-color: @green-light;
				padding: 10px 5px;
				text-transform: uppercase;
			}
		}
		.parks-and-rec {
			img {
				display: block;
				margin: 20px auto;
			}
		}
		.big-phone({
			grid-template-columns: repeat(24, 1fr);
			grid-template-rows: auto 1fr;
			.content {
				grid-row: 1 / span 3;
				grid-column: 1 / span 17;
			}
			.pic {
				grid-row: 1;
				grid-column: 19 / span 6;
			}
			.link {
				.link-text {
					grid-column: 19 / span 6;
					grid-row: 2;
				}
			}
			.parks-and-rec {
				grid-row: 3;
				grid-column: 19 / span 6;
			}

		});
		.big-phone-and-smaller({
			grid-template-columns: 1fr;
			.content {
				grid-row: 1;
			}
			.pic {
				grid-row: 2;
			}
			.link {
				.link-text {
					grid-row: 3;
				}
			}
			.parks-and-rec {
				grid-row: 4;
			}
		});

	}

	&.logo-with-caption {
		.pic {
			margin-top: 20px;
			.ratio(1.1);
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
		}
		.content {
			padding-right: 20px;
			padding-left: 0;
			padding-top: 20px;
			padding-bottom: 20px;
			
			h2 {
				.paytone(40);
				border-bottom: 0;
				margin-top: 0;
			}
			h3 {
				.paytone(25);
				color: @yellow !important;
				border-bottom: 0;
				text-transform: none;
				font-weight: 600;
				margin-top: 0;
			}
			p {
				.bree(21);
				font-weight: 300;
			}
		}
		.link {
			display: none;
		}
		.big-phone({
			grid-template-columns: repeat(24, 1fr);
			grid-template-rows: auto 1fr;
			.content {
				grid-row: 1 / span 2;
				grid-column: 1 / span 17;
			}
			.pic {
				grid-row: 1;
				grid-column: 17 / span 8;
			}
		});
		.big-phone-and-smaller({
			grid-template-columns: 1fr;
			.content {
				grid-row: 1;
			}
			.pic {
				grid-row: 2;
			}
		});
	}

	.big-phone({
		grid-template-columns: 1fr 1fr;
		.pic {
			grid-row: 1;
			grid-column: 1;
		}
		.content {
			grid-row: 1;
			grid-column: 2;
			padding-bottom: 70px;
		}
		.link {
			grid-row: 1;
			grid-column: 2;
			align-self: end;
			justify-self: end;
			padding: 20px 30px;
		}
		
		&.pic-right {
			.pic {
				grid-column: 2;
			}
			.content {
				grid-column: 1;
			}
			.link {
				grid-column: 1;
				justify-self: start;
			}
		}

		&.camps-pic-right {
			.pic {
				grid-column: 2;
			}
			.content {
				grid-column: 1;
			}
			.link {
				grid-column: 1;
				justify-self: start;
			}
			&.wider {
				grid-template-columns: 3fr 1fr;
			}
		}

	});
	.big-phone-and-smaller({
		.content {
			grid-row: 1;
			grid-column: 1 / span 2;
			padding-bottom: 50px;
		}
		
		&.pic-right, &.pic-left {
			.pic {
				grid-row: 2;
				grid-column: 2;
			}
			.link {
				grid-row: 2;
				grid-column: 1;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
		&.camps-pic-right {
			.pic {
				grid-row: 3;
				grid-column: 1 / span 2;
			}
			.link {
				justify-self: left;
				align-self: end;
			}
		}

	});
	
	.phone-and-smaller({
		&.pic-right, &.pic-left {
			.content {
				padding-left: 14px;
				h2 {
					.paytone(24);
				}
			}
			.pic {
				grid-row: 2;
				grid-column: 1 / span 2;
			}
			.link {
				grid-row: 3;
				grid-column: 1 / span 2;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
			}
		}

	});
	
	&.brown {
		background-color: @brown;
		.content {
			h2 {
				border-bottom-color: @brown-light;
			}
		}
		.link {
			background-color: @brown-light;
		}
		&.wild-camps-pic-right, &.wild-camps-pic-left {
			.content {
				padding-top: 7%;
				h2 {
					border-bottom: 0;
					color: @yellow;
					text-align: center;
					strong {
						color: white;
						font-weight: inherit;
					}
				}
				h3 {
					color: white;
					text-align: center;
					text-transform: uppercase;
				}
				p {
					text-align: center;
				}
			}
			.link {
				display: none;
			}
		}
		&.wild-camps-pic-right {
			.content {
				background: rgb(61,51,42);
				background: linear-gradient(90deg, rgba(61,51,42,1) 0%, rgba(61,51,42,1) 60%, rgba(61,51,42,0) 100%);
				padding-right: 40%;
			}
		}
		&.wild-camps-pic-left {
			.content {
				background: rgb(61,51,42);
				background: linear-gradient(90deg,  rgba(61,51,42,0) 0%, rgba(61,51,42,1) 30%, rgba(61,51,42,1) 100%);
			}
		}
	}
	&.yellow {
		background-color: @yellow;
		.content {
			h2 {
				border-bottom-color: @orange;
			}
		}
		.link {
			background-color: @orange;
		}
		
		&.wild-camps-pic-right {
			.content {
				background: rgb(61,51,42);
				background: linear-gradient(90deg, rgba(61,51,42,1) 0%, rgba(61,51,42,1) 60%, rgba(61,51,42,0) 100%);
				h2 {
					border-bottom: 0;
					color: white;
				}
				h3 {
					color: @yellow;
				}
			}
			.link {
				background-color: @yellow;
			}
		}
	}
	&.blue {
		background-color: @blue-light;
		.content {
			h2 {
				border-bottom-color: @blue;
			}
		}
		.link {
			background-color: @blue;
		}
		&.wild-camps-pic-right {
			.content {
				background: rgba(118,174,208,1); 
				background: linear-gradient(90deg, rgba(118,174,208,1) 0%, rgba(118,174,208,1) 60%, rgba(118,174,208,0) 100%);
				h2 {
					border-bottom: 0;
					color: white;
				}
				h3 {
					color: @blue;
				}
			}
			.link {
				background-color: @blue;
			}
		}
	}
	&.purple {
		background-color: @purple-light;
		.content {
			h2 {
				border-bottom-color: @purple;
			}
		}
		.link {
			background-color: @purple;
		}
		&.wild-camps-pic-right {
			.content {
				background: rgba(140,121,174,1);
				background: linear-gradient(90deg, rgba(140,121,174,1) 0%, rgba(140,121,174,1) 60%, rgba(140,121,174,0) 100%);
				h2 {
					border-bottom: 0;
					color: white;
				}
				h3 {
					color: @purple;
				}
			}
			.link {
				background-color: @purple;
			}
		}
		p {
			a {
				color: @blue-dark;
			}
		}
	}
	&.red {
		background-color: @red-light;
		.content {
			h2 {
				border-bottom-color: @red;
			}
		}
		.link {
			background-color: @red;
		}
		&.wild-camps-pic-right {
			.content {
				background: rgba(140,121,174,1);
				background: linear-gradient(90deg, rgba(195,77,79,1) 0%, rgba(195,77,79,1) 60%, rgba(195,77,79,0) 100%);
				h2 {
					border-bottom: 0;
				}
			}
			.link {
				background-color: @red;
			}
		}
	}
	&.green {
		background-color: @green-light;
		.content {
			h2 {
				border-bottom-color: @green;
			}
		}
		.link {
			background-color: @green;
		}
		&.wild-camps-pic-right {
			.content {
				background: rgb(141,188,63);
				background: linear-gradient(90deg, rgba(141,188,63,1) 0%, rgba(141,188,63,1) 60%, rgba(141,188,63,0) 100%);
				h2 {
					border-bottom: 0;
					color: white;
				}
				h3 {
					color: @green;
				}
			}
			.link {
				background-color: @green;
			}
		}
		p {
			a {
				color: @blue-dark;
			}
		}
	}
	
	&.transparent {
		background-color: transparent;
		.content {
			h2, p {
				color: @grey-body-text;
			}
		}
	}
	
	&:hover {
		.link {
			position: relative;
			&:before {
				content: '\00a0';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				opacity: .3;
				background-color: black;
			}
		}
	}
}


.pic-grid {
	display: grid;
	.pic {
		background-position: center center;
		background-size: cover;
		.ratio(0.693110647);
	}
	.big-phone({
		&.four {
			grid-template-columns: repeat(2, 1fr);
		}

	});
	.tablet({
		&.four {
			grid-template-columns: repeat(4, 1fr);
		}
	});
}

.yellow-circle {
	.ratio(1);
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background-color: @yellow;
	border-radius: 50%;
	img {
		display: block;
	}
}

.financial-report {
	display: grid;
	column-gap: 40px;
	row-gap: 10px;
	padding-top: 20px;
	padding-bottom: 20px;
	.pic {
		.ratio(1.6);
		background-size: contain;
		background-position: top center;
		background-repeat: no-repeat;
	}
	.content {
		padding-right: @outside-page-padding;
		h1, h2, h3 {
			margin-top: 0;
			font-weight: 600;
		}
		
		h1 {
			.paytone(35);
		}
	}
	
	&.program-layout {
		border-top: 0 !important;
		background-color: @grey-page-section-back;
		padding-top: 0px;
		padding-bottom: 0px;
		margin-top: 20px;
		margin-bottom: 20px;
		.pic {
			.ratio(1);
			background-size: cover;
		}
		.content {
			padding-bottom: 20px;
			padding-top: 20px;
		}
	}

	.big-phone({
		grid-template-columns: 174px auto ;
	});
	.big-phone-and-smaller({
		.pic {
			margin: auto;
			width: 174px;
		}
	});
	
	& + & {
		border-top: solid 4px @grey-line;
	}
}


.nature-for-all {
	display: grid;
	background-color: @grey-page-section-back;
	.ready-to-load-img.stone-pocked-pattern-picture-background();
	overflow: hidden;
	
	.left-msg {
		padding-top: 30px;
		padding-bottom: 30px;
		h1 {
			.paytone(40);
			margin-bottom: 0;
			text-transform: uppercase;
		}
		h2 {
			.paytone(25);
			color: @yellow;
			text-transform: uppercase;
		}
	}
	
	.right-msg {
		background-color: @yellow;
		position: relative;
		text-align: center;
		
		.yellow-curve-picture img {
			position: absolute;
			top: 50%;
			right: 80%;
			transform: translateY(-50%);
		}
		.msg {
			position: relative;
			z-index: 2;
		}
		h1 {
			.paytone(25);
		}
	}
	.right-margin {
		background-color: @yellow;
	}
	.tablet({
		grid-template-columns: minmax(@page-side-padding, 1fr) minmax(100px, @container-width) minmax(@page-side-padding, 1fr);
		
		.cent {
			display: grid;
			grid-template-columns: 7fr 2fr;
		}
		.right-msg {
			display: flex;			

			align-items: center;
		}
	});
	.tablet-and-smaller({
		.right-msg {
			padding-top: 20px;
			padding-bottom: 20px;
			.yellow-curve-picture {
				display: none;
			}
		}
		.left-msg {
			padding-left: @page-side-padding;
			padding-right: @page-side-padding;
		}

	});
	
}

.stay-in-touch {
	display: grid;
	overflow: hidden;
	position: relative;
	.left-margin, .left-msg {
		background-color: @blue-light;
	}

	
	.left-msg {
		padding-top: 30px;
		padding-bottom: 30px;
		h1 {
			.paytone(40);
			color: black;
			margin-bottom: 0;
			text-transform: uppercase;
		}
		h2 {
			.paytone(25);
			color: @blue;
			text-transform: uppercase;
			margin-top: 0;
		}
	}
	
	.right-img {
		picture {
			img {
				object-position: left center;
			}

		}
	}

	.tablet({
		grid-template-columns: minmax(@page-side-padding, 1fr) minmax(100px, @container-width) minmax(@page-side-padding, 1fr);
		
		.cent {
			display: grid;
			grid-template-columns: 7fr 2fr;
		}
		.right-msg {
			display: flex;			

			align-items: center;
		}
		.right-img {
			position: absolute;
			left: 50%;
			right: 0;
			top: 0;
			bottom: 0;
			overflow: hidden;
			
			@size: 650px;
			
			
			&:before {
				content: '\00a0';
				font-size: 0;
				
				position: absolute;
				top: 50%;
				left: 40px;
				transform: translate(-100%, -50%);

				height: @size;
				width: @size;
				box-sizing: border-box;
				border-radius: 50%;
				background-color: @blue-light
			}
		}
		
	});
	.tablet-and-smaller({
		.right-msg {
			padding-top: 20px;
			padding-bottom: 20px;
			.yellow-curve-picture {
				display: none;
			}
		}
		.left-msg {
			padding-left: @page-side-padding;
			padding-right: @page-side-padding;
		}
		
		.right-img {
			display: block;
			aspect-ratio: 3;
		}

	});
	
}
.become-a-member-bar {
	.nature-for-all;
	background-color: @brown;
	.left-msg, .left-margin {
		background-color: @yellow;
	}
	.left-msg {
		position: relative;
	}
	.right-msg, .right-margin {
		background-color: @brown;
	}
	
	.right-msg {
		.yellow-curve-picture img {
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%) rotate(180deg);
		}
	}
	
	.tablet({
		.cent {
			grid-template-columns: 5fr 5fr;
		}
		.right-msg {
			padding-left: 50%;
		}
	});
}

.video-widget {
	.content {
		text-align: center;
	}
}
.video-holder {
	.ratio(9/16);
	position: relative;
	iframe, video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.program-link-tile {
	display: grid;
	grid-template-columns: repeat(24, 1fr);
	margin-top: 20px;
	margin-bottom: 20px;
	text-decoration: none;
	color: @brown;
	.pic {
		.ratio(1);
		grid-column: 1 / span 6;
		background-position: center center;
		background-size: cover;
	}
	.msg {
		background-color: @grey-page-section-back;
		grid-column: 7 / span 17;
		padding: 5px 20px;
		
		h2 {
			.paytone(35);
			text-transform: uppercase;
		}
	}
	.bar {
		background-color: @yellow;
		display: flex;
		align-items: center;
		text-align: center;
		justify-items: center;
		grid-column: 24;
		
		picture {
			display: block;
			margin: auto;
		}
	}
}

.field-trips-mega-expando {
	padding-top: 2px !important;
	padding-bottom: 0 !important;
	margin-bottom: 10px;
	
	.expando:first-child {
		.opener {
			margin-top: 0;
		}
	}
	
	.expando {
		.opener {
			strong {
				.bree(21);
				color: @grey-body-text;
				text-transform: none;
			}
		}
		.opener:after {
			content: url('/img/chevron-down-in-yellow-circle.png');
		}
		.expando {
			.opener {
				.bree(21);
				position: relative;
				color: @brown;
				text-transform: none;
				font-weight: 600;
				padding-left: 40px;
				
				strong {
					.bree(21);
					color: @grey-lighter-expando;
				}
				
				&:after {
					content: url('/img/chevron-down-yellow.png');
					transform: rotate(-90deg);
					position: absolute;
					left: 0;
					top: 15px;
				}
			}
			
			&.open {
				.opener {
					&:after {
						transform: rotate(0deg);
					}
				}
			}
		}
	}
}

.pricing-grid {
	img {
		display: block;
		max-width: 100%;
		margin: auto;
	}
	p {
		margin: 0;
	}
}

.price-table() {
	width: 100%;
	th {
		color: white;
	}
	
	td, th {
		padding: 6px 8px;
		text-align: center;
	}
	td:first-child, th:first-child {
		text-align: left;
	}
}

table.price-table-blue {
	.price-table();
	th {
		background-color: @blue-light;
	}
}
table.price-table-purple {
	.price-table();
	th {
		background-color: @purple-light;
	}
}

table.related-events {
	border-spacing: 2px;
	border-collapse: separate;
	th, td {
		padding: 5px 10px;
		font-weight: 300;
	}
	
	& > tbody > tr > th {
		background-color: @blue-light;
		color: white;
		text-align: left;
	}
	
	& > tbody > tr > td {
		vertical-align: top;
	}
	& > tbody > tr > td:last-child {
		padding-top: 0;
	}
	& > tbody > tr > td:first-child {
		// background-color: @grey-line;
		&:after {
			content: '\00a0';
			// display: inline-block;
			margin-right: -10px;
			float: right;
			border-right: solid 1px @grey-line;
			margin-top: 5px;
		}
	}
	
	a {
		color: @yellow;
	}
}


html:not(.editing-page) {
	.rotator.shiner-show {
		display: grid;
		grid-template-columns: auto 1fr auto;
		.advance {
			grid-row: 1;
			grid-column: 2;
		}
		.previous {
			grid-row: 1;
			grid-column: 1;
		}
		.dots {
			grid-row: 2;
			grid-column: span 3;
		}

		.content {
			display: contents;
		}
		.content > * {
			display: none;
		}

		.slide {
			display: block;
			opacity: 0;
			grid-row: 1;
			grid-column: 2;
		}
		

		.slide:first-child {
			opacity: 1;
		}

		.slide.invisible {
			opacity: 0;
		}

		.slide.visible {
			opacity: 1;
		}

		.container {
			position: relative;
			z-index: 5;
		}
		
		&.transitions-on {
			.slide {
				transition: opacity 1s;
			}
		}
		
		.dots {
			text-align: center;
			.dot {
				display: inline-block;
				position: relative;
				width: 15px;
				height: 15px;
				border-radius: 50%;
				margin-left: 10px;
				margin-right: 10px;
				border: 2px solid @yellow;
				transition: background-color 1s;
				cursor: pointer;
				&.current {
					background-color: @yellow;

				}
			}
		}
	}

}

.ei-two-grid {
	.right-then-center {
		text-align: center;
		.tablet({
			text-align: right;
		})
	}
}

.sponsor-logo-group {
	text-align: center;
	display: grid;
	
	.tablet({
		grid-template-columns: repeat(3, 1fr);
		align-items: end;
	});

	.sponsor-logo {
		vertical-align: bottom;
		display: inline-block;
		// width: 250px;
		img {
			width: 100%;
		}
	}
}


.related-event-start-date {
	width: 30px;
	text-align: right;
	padding-left: 0 !important;
	white-space: nowrap;
}