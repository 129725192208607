
.setup-width-definitions() {
	@container-width:			875px;
	@tablet-width: 				800px;
	@tablet-small-width:        716px;
	@big-phone-width:			650px;
	@phone-width:               550px;
	@iphone-4-landscape-width:	480px;
	@iphone-4-width: 			320px;
	@page-side-padding:			20px;
	@outside-page-padding:		58px;

}

.setup-width-definitions();


/*
 * The base font size which makes the chosen font match photoshop.
 * For Open Sans this is 7.1pt, for Source Sans Pro this is 7.6pt 
 */
@base-font-size:			7.6pt;
@paytone-font-size: 		6.645226131pt;
@bree-font-size: 			7.252112676pt;
@candal-font-size: 			7.252112676pt;


@grey-page-section-back: 	#f3f2ee;
@grey-history-darker-back: 	#e2e1de;
@grey-line: 				#e5e3df;
@grey-lighter-expando:		#8b857f;
@grey-body-text:			@brown;


@brown-year: 				#a09285;
@brown-line: 				#78716a;
@brown-light: 				#685646;
@brown: 					#3d332a;


@yellow: 					#f2af20;

@orange: 					#f27e20;

@blue-light: 				#76aed0;
@blue: 						#3681af;
@blue-dark: 				#1a4f70;

@purple-light: 				#8c79ae;
@purple: 					#624b8a;


@red-required: 				#ef3e00;
@red-light: 				#c34d4f;
@red: 						#81192c;

@green-light: 				#8dbc3f;
@green: 					#6a971f;


@color-body-text: 			@brown;


.source-sans-pro() {
	font-family: 'Source Sans Pro', sans-serif;
}

.open-sans() {
    font-family: 'Open Sans', sans-serif;
}

.paytone {
	font-family: 'Paytone One', sans-serif;
}
.paytone(@fontSize) {
	font-family: 'Paytone One', sans-serif;
	font-size: @paytone-font-size * @fontSize / 10;
}

.bree {
	font-family: 'Bree Serif', serif;
}
.bree(@fontSize) {
	font-family: 'Bree Serif', serif;
	font-size: @bree-font-size * @fontSize / 10;
}

.candal {
	font-family: 'Candal', sans-serif;
}
.candal(@fontSize) {
	font-family: 'Candal', sans-serif;
	font-size: @candal-font-size * @fontSize / 10;
}

.menu-link() {
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}	

.link-inherit() {
	a {
		.menu-link;
		font-size: inherit;
		color: inherit;
		font-weight: inherit;
		line-height: inherit;
		font-family: inherit;
	}
}


h1, h2, h3, h4, h5 {
	.link-inherit;
}

h2, h3 {
	font-weight: 400;
}

body {
	.bree;
    color: @brown;
	font-weight: 400;
}
