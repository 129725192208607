

.std-header {
	color: white;
	background-color: black;

	padding: 20px; 
	.tablet({
		padding: 20px @outside-page-padding 20px @outside-page-padding;

	});
	
	.logo {
		color: white;
		text-decoration: none;
		img {
			max-width: 80%;
		}
	}
	
	.menu {
		list-style: none;
		li {
			margin-left: 10px;
			padding-bottom: 10px;
			a {
				.fs(20);
				color: white;
				text-transform: uppercase;
				text-decoration: none;
				font-weight: 800;
				padding-left: 10px;
				padding-right: 10px;
				padding-bottom: 6px;	
				padding-top: 2px;
			}
		}
	}

	.desktop({
		display: grid;
		grid-template-columns: auto 1fr;
	
		nav {
			justify-self: right;
			align-self: center;
			text-align: right;

		}
		.menu {
			display: inline-block;
			margin: 0;
			padding: 0;
			li {
				display: inline-block;
				a {
				}
				
				&.current {
					border-bottom: solid 2px white;
				}
				
			}
		}
		.tribar {
			display: none;
		}
	});
	.desktop-and-smaller({
		nav {
			position: fixed;
			width: 100%;
			width: 100vw;
			height: 100%;
			height: 100vh;
			z-index: 5;
			top: 0;
			left: -20000px;
			max-width: 350px;
			opacity: 0;
			transition: opacity .3s;
			overflow: auto;
			padding: 50px 15px 0 15px;
			box-sizing: border-box;
			background-color: @green; 
			.menu {
				padding: 0;
			}
			
			& > li {
				display: block;
				text-align: center;
				
				& > a {
					text-align: center;
					font-weight: 600;
				}
				
				& > ul {
					position: relative;
					left: auto;
					opacity: 1;
					text-align: center;
					
					a {
						text-align: center;
					}
				}
			}
			
			&.open {
				right: 0;
				opacity: 1;
			}
		}

		.tribar {
			width: 30px;
			margin: 10px 10px 0 0;
			cursor: pointer;
			position: absolute;
			top: 10px;
			right: 10px;
			
			.one, .two, .three {
				border-bottom: solid 3px;
				border-color: white;
			}
			.two {
				margin: 7px 0;
			}
			
		}
	});

}

// header {
// 	.std-header;
// }

.plan-a-day {
	text-align: center;
	padding-left: 60px;
	padding-right: 60px;
	margin-top: 80px;
	
	h1 {
		.paytone(40);
		margin-bottom: 30px;
	}
	h2 {
		.paytone(21);
		margin-bottom: 0;
	}
	p {
		.bree(21);
	}
	
	.ei-two-grid {
		text-align: left;
		h2 {
			.paytone(25);
		}
		.col2 {
			h3 {
				.bree(21);
			}
			p {
				.bree(16);	
			}

		}
	}
	.big-phone-and-smaller({
		padding-left: @page-side-padding;
		padding-right: @page-side-padding;
	});
}

.page-footer {
	background-color: @brown;
	color: white;
	padding-top: 40px;
	
	.top {
		display: grid;
		h2, h3 {
			margin-top: 0;
		}
		.contact, .support {
			h2 {
				.paytone(17);
				padding-bottom: 3px;
				border-bottom: solid 2px @brown-line;
				text-transform: uppercase;
			}
			p {
				.bree(15);
				strong {
					color: @yellow;
				}
				a {
					text-decoration: none;
					color: @yellow;
					font-weight: 600;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
		.about {
			text-align: right;
			h2 {
				.paytone(27);
				line-height: 1.1em;
			}
			p {
				.bree(15);
			}
		}
		.tablet({
			grid-template-columns: repeat(4, 1fr);
			column-gap: 40px;
			row-gap: 30px;
			
			.about {
				grid-column: span 2;
				padding-left: 80px;
				
			}

		});
		.tablet-and-smaller({
			row-gap: 20px;
			.about {
				grid-row: 1;
			}
		});
	}
	.bottom {
		display: grid;
		margin-top: 50px;
		.social {
			text-align: center;
			.icons {
				.yellow-circle {
					width: 70px;
					margin: 10px;
				}
			}
			.txt {
				.bree(13);
				padding-bottom: 20px;
				a {
					color: @yellow;
					text-decoration: none;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
		.tablet({
			grid-template-columns: repeat(3, 1fr);

			.leaves {
				padding-left: 20px;
				padding-top: 30px;
				align-self: end;
				img {
					display: block;
					margin-left: auto;
				}
			}

		});
		.tablet-and-smaller({
			.leaves, .blank {
				display: none;
			}

		});

	}
	
	
}



.editable-page-chunk {
	
}
section.history {
	margin-bottom: 100px;
}
.history-items {
	@history-circle-size: 60px;
	
	.item {
		position: relative;
		min-height: @history-circle-size - 24;
	}
	
	strong {
		color: @brown;
		font-size: 110%;
	}
	
	.on-right {
		padding-left: 15px;
		float: right;
		max-height: 120px;
		padding-bottom: 15px;
	}
	
	.on-left {
		padding-right: 15px;
		float: left;
		max-height: 120px;
		padding-bottom: 15px;
	}
	
	h2 {
		margin: 0;
	}
	
	.img {
		div, h1, h2, h3, h4, p {
			color: white;
		}
		
		
		.text {
			background-color: @brown;
			padding-bottom: 17px;
			
			h3 {
				margin-top: 0;
			}
			
			strong {
				color: white;
			}
		}
		
		.dotted, .dot {
			display: none;
		}
	}
	
	.dotted {
		border-bottom: dotted 2px @yellow;
		position: absolute;
		top: 32px;
		left: 62px;
		z-index: 8;
		width: 30px;
		font-size: 1px;
		text-align: right;
		
	}
	.dot {
		display: none;
		font-size: 44pt;
		position: absolute;
		top: 28px;
		line-height: 2px;
		left: 90px;
		z-index: 8;
		
		border-radius: 50%;
		width: 10px;
		height: 10px; 
		
		background-color: @green;
	}
	.back-img {
		position: absolute;
		left: 32px;
		top: 0;
		right: 0;
		bottom: 0;
		overflow: hidden;
		z-index: 5;
		
		img {
			min-width: 100%;
			min-height: 100%;
		}
	}
	.year {
		background-color: @yellow;
		border-radius: 50%;
		width: @history-circle-size;
		height: @history-circle-size; 
		text-align: center;
		line-height: @history-circle-size;
		color: white;
		position: absolute;
		left: 0;
		z-index: 10;
		font-size: 14pt;
	}
	.text {
		position: relative;
		margin-left: 32px;
		padding-left: 73px;
		padding-right: 20px;
		padding-bottom: 20px;
		padding-top: 18px;
		background-color: @grey-page-section-back;
		min-height: @history-circle-size - 24;
		z-index: 7;
	}
	
	.slightly-darker .text {
		background-color: @grey-history-darker-back;
	}
	.darker .text {
		background-color: @brown;
		color: white;
		strong {
			color: white;
		}
	}
	
	.more {
		display: none;
	}
	
	div, p, a {
		.bree(19);
	}
	
	p {
		margin-top: 0;
	}
}

.application-form {
	margin-bottom: 80px;
}

.faqs {
	h2 {
		color: @brown;
	}
	.expando {
		.opener {
			color: @grey-body-text;
			text-transform: none;
			.bree(21);
			font-weight: 600;
		}
	}
}

section.calendar {
	.years {
		margin-top: 10px;
		margin-bottom: 10px;
		.year {
			background-color: @grey-page-section-back;
			color: @brown-year;
			display: inline-block;
			cursor: pointer;
			margin-right: 20px;
			padding: 10px 25px;
			font-weight: 600;
			
			&.active {
				background-color: @green-light;
				color: white;
			}
			
		}
	}
	
	.months {
		margin-top: 10px;
		margin-bottom: 10px;
		margin-right: 15px;
		
		.tablet({
			display: flex;
			justify-content: space-between;
		});

		& > div {
			display: inline-block;
			text-transform: uppercase;
			color: @grey-body-text;
			cursor: pointer;
			padding: 5px 15px 7px;
			
			&.active {
				color: white;
				background-color: @green-light;
				padding-left: 10px;
				padding-right: 10px;
			}
		}
	}
	
	.month-marker {
		background-color: @grey-page-section-back;
		color: @yellow;
		padding: 5px 15px;
	}
	// .tablet({
	// 	.month-marker.stuck {
	// 		position: absolute;
	// 		top: 0;
	// 		left: 0;
	// 		right: 35px;
	// 		margin-top: 0;
	// 		background-color: white;
	// 		z-index: 3;
	// 	}
	// });
	// .tablet-and-smaller({
	// 	.month-marker.stuck {
	// 		position: relative;
	// 	}
	// });
	
	.all-events {
		position: relative;
		.tablet({
			max-height: 900px;
			padding-right: 15px;
			overflow: scroll;
		});
		
	}
	
	
	.event {
		padding-top: 25px;
		padding-bottom: 25px;
		padding-right: 10px;
		position: relative;
		border-top: 2px solid @grey-line;
		
		
		
		.price-box {
			color: @green;
			float: right;
			right: 10px;
			text-align: right;
			width: 195px;
			padding-bottom: 10px;
			
			.actual-price {
				color: @green;
				font-weight: 400;
			}
			
			img {
				float: left;
			}
			
		
		}
		
		.summary, .summary span {
			color: @green;
			font-size: 12pt;
			font-weight: 400;
			text-decoration: underline;
		}
		
		.time, .time * {
			color: @orange;
			font-size: 12pt;
			font-weight: 400;
		}
		
		.description {
			margin-top: 18px;
			
			
			a {
				color: @orange;
			}
		}
		
		.read-more {
			float: right;
			margin-top: -1em;
		}
		
		.other-times {
				
			a {
				text-decoration: underline;
			}
		}
		
		.age-marker {
			float: left;
			margin-top: 4px;
			
			&:after {
				background-position: center center;
				background-size: 100% 100%;
				width: 30px;
				color: white;
				margin-right: 15px;
				padding: 3px 5px 3px 5px;
				background-repeat: no-repeat;;
			}
			
			&.PRESCHOOL:after {
				background-image: url('/vrsc/123/img/purple-rip-clip.png');
				content: 'PreK';
			}
			&.YOUTH:after {
				background-image: url('/vrsc/123/img/blue-rip-clip.png');
				content: 'Youth';
			}
			&.ADULT:after {
				background-image: url('/vrsc/123/img/orange-rip-clip.png');
				content: 'Adult';
			}
			&.ALLAGES:after {
				background-image: url('/vrsc/123/img/orange-rip-clip.png');
				content: 'All';
			}
		}
		
		&.past {
			.summary span, .price-box .actual-price, .price-box a, .time span, 
			.description, .description .read-more {
				color: @grey-lighter-expando;
			}
		}
		
	}
	.month-marker-holder + .event {
		border-top: 0;
	}
	
}

.event-details {
	.clear-inner;
	.calendar-content {
		.clear-inner;
	}
	
	.all-events {
		margin-top: 40px;
		margin-bottom: 40px;
		
		.tablet({
			display: grid;
			grid-template-columns: minmax(50%, 66%) 1fr;
			column-gap: 20px;
			
			.related-events {
				align-self: start;
			}
			
			.pic {
				align-self: start;
			}

		});
		.tablet-and-smaller({
			margin-top: 10px;
			.pic {
				margin-top: 30px;
			}
			.related-events {
				margin: auto;
			}

		});
		
		.pic {
			img {
				width: 100%;
				display: block;
				margin: auto;
				max-width: 400px;
			}
		}
	}
	h1 {
		.candal;
	}
	
	.top {
		display: grid;
		row-gap: 20px;
		column-gap: 20px;
		h2 {
			color: @yellow;
			.candal;
			margin-bottom: 0;
		}
		h3 {
			margin-top: 6px;
			font-weight: 600;
		}
		.price-box {
			a {
				color: @yellow;
			}
		}
		.tablet({
			grid-template-columns: 1fr auto;
			.price-box {
				text-align: right;
				margin-top: 20px;
			}
		});
	}
	.tablet-and-smaller({
		h1 {
			margin-bottom: 4px;
		}
		.top {
			h2 {
				margin-top: 0;
			}
			h3 {
				margin-bottom: 0;
			}
		}

	});
}

.staff-profile-cells {
	position: relative;
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	overflow: hidden;
	
	.big-phone ({
		grid-template-columns: repeat(2, 1fr);
	});
	.desktop ({
		grid-template-columns: repeat(4, 1fr);
	});

	.staff-profile {
		img {
			max-width: 100%;
		}
		.closed-pic {
			background-position: center center;
			background-size: cover;
			position: relative;
			.ratio(1);
			
			.name-overlay {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				opacity: 0;
				font-size: 0;
				background-color: @orange;
				transition: opacity .3s;
			}
		}
		
		.details {
			position: absolute;
			left: 0;
			display: none;
			width: 100%;
			
			.inner-details {
				display: grid;
				grid-template-columns: 1fr 3fr;
				column-gap: 20px;
				padding: 20px;
				background-color: @grey-page-section-back;
			}
			
			.child-pic {
				img {
					width: 100%;
				}

			}
		}
		
		.details-height {
			transition: min-height .3s;
		}
		
		&.show {
			.details {
				display: grid;
				z-index: 4;
			}
		}
		
		&:hover, &.show {
			.closed-pic {
				.name-overlay {
					opacity: .6;
				}
			}
			
		}
	}
}


.board-members {
	margin-top: 50px;
	background-color: @grey-page-section-back;
	
	.opener {
		color: @yellow;
		margin-bottom: 0;
		.paytone !important;
		
		small {
			.bree !important;
			color: @grey-body-text;
			text-transform: none;
			font-weight: 300;
			padding-left: 30px;
		}
	}
	.view {
		.members-list {
			list-style: none;
			column-gap: 30px;
			padding-left: 0;

			.tablet({
				columns: 2;

			});

			li {
				margin-bottom: 10px;
				break-inside: avoid;
				
				strong {
					display: block;
				}
			}
			
		}
	}
}

.edit-content-inline.std-txt, .edit-content-inline .std-txt {
	img {
		max-width: 100%;
	}

}

.ei-form {
	label {
		.edit-content-inline {
			p:first-child {
				margin: 0;
			}
		}
	}
}

.adobe-financial-report {
	width: 100%;
	aspect-ratio: 1.4;
}