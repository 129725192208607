@import (inline) "normalize.css";

@import "definitions";
// @import "../node_modules/webhandle-menus-1/less/webhandle-menus-1/drop-down-menu.less";
// @import (reference) "../node_modules/ei-grid-1/less/grid.less";
@import "../node_modules/@dankolz/ei-css-grid/grid.less";

.ei-css-grid-tight {
	.ei-css-grid-fn(0, 0);	
}

.ei-form {
	@import "../node_modules/ei-form-styles-1/less/ei-form-styles-1";
	
	.btn {
		border-radius: 0;
	}
	.btn-primary {
		color: white;
		background-color: @green;
		border: @green;
		&:hover {
			background-color: darken(@green, 10%);
		}
	}
	
	label {
	}
	.req {
		color: @red-required;
	}
	.actions {
		margin-top: 30px;
	}
	
	input[type=checkbox], input[type=radio] {
		display: inline-block;
		width: 30px;
	}
}
@import (less) "../node_modules/@dankolz/ei-css-grid/grid";

@import "basics";
@import "images";
@import "components";

@import "layouts";

