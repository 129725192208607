
.setup-defaults() {
	@cdn0: '';
	@cdn1: '';
	@cdn2: '';
	@cdn3: '';
}


.setup-defaults();


.50-year-logo-no-title-picture img {
	width: 116.5px;
}

.ready-to-load-img .50-year-logo-no-title-picture-background, .ready-to-load-img .50-year-logo-no-title-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/50-year-logo-no-title.png");
	
	.webp & {
		background-image: url("@{cdn0}/img/50-year-logo-no-title.webp");
	}
	@media (max-width: 58.25px) {

		background-image: url("@{cdn0}/img/50-year-logo-no-title-half.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/50-year-logo-no-title-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/50-year-logo-no-title-2x.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/50-year-logo-no-title-2x.webp");
		}
		
		@media (max-width: 58.25px) {

			background-image: url("@{cdn0}/img/50-year-logo-no-title.png");
			
			.webp & {
				background-image: url("@{cdn0}/img/50-year-logo-no-title.webp");
			}
		}
	}
}

.50-year-logo-no-title-picture-background-ratio, .50-year-logo-no-title-picture-background-with-ratio {
	.ratio(1.5107296137339057);
	background-position: center center;
	background-size: cover;
}

.50-year-logo-white-stroke-picture img {
	width: 52px;
}

.ready-to-load-img .50-year-logo-white-stroke-picture-background, .ready-to-load-img .50-year-logo-white-stroke-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/50-year-logo-white-stroke.png");
	
	.webp & {
		background-image: url("@{cdn1}/img/50-year-logo-white-stroke.webp");
	}
	@media (max-width: 26px) {

		background-image: url("@{cdn1}/img/50-year-logo-white-stroke-half.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/50-year-logo-white-stroke-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/50-year-logo-white-stroke-2x.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/50-year-logo-white-stroke-2x.webp");
		}
		
		@media (max-width: 26px) {

			background-image: url("@{cdn1}/img/50-year-logo-white-stroke.png");
			
			.webp & {
				background-image: url("@{cdn1}/img/50-year-logo-white-stroke.webp");
			}
		}
	}
}

.50-year-logo-white-stroke-picture-background-ratio, .50-year-logo-white-stroke-picture-background-with-ratio {
	.ratio(1.4230769230769231);
	background-position: center center;
	background-size: cover;
}

.50-year-logo-picture img {
	width: 187px;
}

.ready-to-load-img .50-year-logo-picture-background, .ready-to-load-img .50-year-logo-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/50-year-logo.png");
	
	.webp & {
		background-image: url("@{cdn2}/img/50-year-logo.webp");
	}
	@media (max-width: 93.5px) {

		background-image: url("@{cdn2}/img/50-year-logo-half.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/50-year-logo-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/50-year-logo-2x.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/50-year-logo-2x.webp");
		}
		
		@media (max-width: 93.5px) {

			background-image: url("@{cdn2}/img/50-year-logo.png");
			
			.webp & {
				background-image: url("@{cdn2}/img/50-year-logo.webp");
			}
		}
	}
}

.50-year-logo-picture-background-ratio, .50-year-logo-picture-background-with-ratio {
	.ratio(1.8128342245989304);
	background-position: center center;
	background-size: cover;
}

.chevron-down-in-yellow-circle-picture img {
	width: 32px;
}

.ready-to-load-img .chevron-down-in-yellow-circle-picture-background, .ready-to-load-img .chevron-down-in-yellow-circle-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/chevron-down-in-yellow-circle.png");
	
	.webp & {
		background-image: url("@{cdn3}/img/chevron-down-in-yellow-circle.webp");
	}
	@media (max-width: 16px) {

		background-image: url("@{cdn3}/img/chevron-down-in-yellow-circle-half.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/chevron-down-in-yellow-circle-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/chevron-down-in-yellow-circle-2x.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/chevron-down-in-yellow-circle-2x.webp");
		}
		
		@media (max-width: 16px) {

			background-image: url("@{cdn3}/img/chevron-down-in-yellow-circle.png");
			
			.webp & {
				background-image: url("@{cdn3}/img/chevron-down-in-yellow-circle.webp");
			}
		}
	}
}

.chevron-down-in-yellow-circle-picture-background-ratio, .chevron-down-in-yellow-circle-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.chevron-down-yellow-picture img {
	width: 22px;
}

.ready-to-load-img .chevron-down-yellow-picture-background, .ready-to-load-img .chevron-down-yellow-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/chevron-down-yellow.png");
	
	.webp & {
		background-image: url("@{cdn0}/img/chevron-down-yellow.webp");
	}
	@media (max-width: 11px) {

		background-image: url("@{cdn0}/img/chevron-down-yellow-half.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/chevron-down-yellow-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/chevron-down-yellow-2x.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/chevron-down-yellow-2x.webp");
		}
		
		@media (max-width: 11px) {

			background-image: url("@{cdn0}/img/chevron-down-yellow.png");
			
			.webp & {
				background-image: url("@{cdn0}/img/chevron-down-yellow.webp");
			}
		}
	}
}

.chevron-down-yellow-picture-background-ratio, .chevron-down-yellow-picture-background-with-ratio {
	.ratio(0.6363636363636364);
	background-position: center center;
	background-size: cover;
}

.chevron-right-in-white-circle-picture img {
	width: 43px;
}

.ready-to-load-img .chevron-right-in-white-circle-picture-background, .ready-to-load-img .chevron-right-in-white-circle-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/chevron-right-in-white-circle.png");
	
	.webp & {
		background-image: url("@{cdn1}/img/chevron-right-in-white-circle.webp");
	}
	@media (max-width: 21.5px) {

		background-image: url("@{cdn1}/img/chevron-right-in-white-circle-half.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/chevron-right-in-white-circle-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/chevron-right-in-white-circle-2x.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/chevron-right-in-white-circle-2x.webp");
		}
		
		@media (max-width: 21.5px) {

			background-image: url("@{cdn1}/img/chevron-right-in-white-circle.png");
			
			.webp & {
				background-image: url("@{cdn1}/img/chevron-right-in-white-circle.webp");
			}
		}
	}
}

.chevron-right-in-white-circle-picture-background-ratio, .chevron-right-in-white-circle-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.chevron-right-white-picture img {
	width: 22px;
}

.ready-to-load-img .chevron-right-white-picture-background, .ready-to-load-img .chevron-right-white-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/chevron-right-white.png");
	
	.webp & {
		background-image: url("@{cdn2}/img/chevron-right-white.webp");
	}
	@media (max-width: 11px) {

		background-image: url("@{cdn2}/img/chevron-right-white-half.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/chevron-right-white-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/chevron-right-white-2x.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/chevron-right-white-2x.webp");
		}
		
		@media (max-width: 11px) {

			background-image: url("@{cdn2}/img/chevron-right-white.png");
			
			.webp & {
				background-image: url("@{cdn2}/img/chevron-right-white.webp");
			}
		}
	}
}

.chevron-right-white-picture-background-ratio, .chevron-right-white-picture-background-with-ratio {
	.ratio(1.5454545454545454);
	background-position: center center;
	background-size: cover;
}

.chevron-right-yellow-picture img {
	width: 10.5px;
}

.ready-to-load-img .chevron-right-yellow-picture-background, .ready-to-load-img .chevron-right-yellow-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/chevron-right-yellow.png");
	
	.webp & {
		background-image: url("@{cdn3}/img/chevron-right-yellow.webp");
	}
	@media (max-width: 5.25px) {

		background-image: url("@{cdn3}/img/chevron-right-yellow-half.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/chevron-right-yellow-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/chevron-right-yellow-2x.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/chevron-right-yellow-2x.webp");
		}
		
		@media (max-width: 5.25px) {

			background-image: url("@{cdn3}/img/chevron-right-yellow.png");
			
			.webp & {
				background-image: url("@{cdn3}/img/chevron-right-yellow.webp");
			}
		}
	}
}

.chevron-right-yellow-picture-background-ratio, .chevron-right-yellow-picture-background-with-ratio {
	.ratio(1.4285714285714286);
	background-position: center center;
	background-size: cover;
}

.document-icon-picture img {
	width: 13.5px;
}

.ready-to-load-img .document-icon-picture-background, .ready-to-load-img .document-icon-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/document-icon.png");
	
	.webp & {
		background-image: url("@{cdn0}/img/document-icon.webp");
	}
	@media (max-width: 6.75px) {

		background-image: url("@{cdn0}/img/document-icon-half.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/document-icon-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/document-icon-2x.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/document-icon-2x.webp");
		}
		
		@media (max-width: 6.75px) {

			background-image: url("@{cdn0}/img/document-icon.png");
			
			.webp & {
				background-image: url("@{cdn0}/img/document-icon.webp");
			}
		}
	}
}

.document-icon-picture-background-ratio, .document-icon-picture-background-with-ratio {
	.ratio(1.3333333333333333);
	background-position: center center;
	background-size: cover;
}

.facebook-logo-picture img {
	width: 15px;
}

.ready-to-load-img .facebook-logo-picture-background, .ready-to-load-img .facebook-logo-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/facebook-logo.png");
	
	.webp & {
		background-image: url("@{cdn1}/img/facebook-logo.webp");
	}
	@media (max-width: 7.5px) {

		background-image: url("@{cdn1}/img/facebook-logo-half.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/facebook-logo-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/facebook-logo-2x.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/facebook-logo-2x.webp");
		}
		
		@media (max-width: 7.5px) {

			background-image: url("@{cdn1}/img/facebook-logo.png");
			
			.webp & {
				background-image: url("@{cdn1}/img/facebook-logo.webp");
			}
		}
	}
}

.facebook-logo-picture-background-ratio, .facebook-logo-picture-background-with-ratio {
	.ratio(2);
	background-position: center center;
	background-size: cover;
}

.field-trips-at-quarry-hill-tile-1-picture img {
	width: 479px;
}

.ready-to-load-img .field-trips-at-quarry-hill-tile-1-picture-background, .ready-to-load-img .field-trips-at-quarry-hill-tile-1-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/field-trips-at-quarry-hill-tile-1.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/field-trips-at-quarry-hill-tile-1.webp");
	}
	@media (max-width: 239.5px) {

		background-image: url("@{cdn2}/img/field-trips-at-quarry-hill-tile-1-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/field-trips-at-quarry-hill-tile-1-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/field-trips-at-quarry-hill-tile-1-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/field-trips-at-quarry-hill-tile-1-2x.webp");
		}
		
		@media (max-width: 239.5px) {

			background-image: url("@{cdn2}/img/field-trips-at-quarry-hill-tile-1.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/field-trips-at-quarry-hill-tile-1.webp");
			}
		}
	}
}

.field-trips-at-quarry-hill-tile-1-picture-background-ratio, .field-trips-at-quarry-hill-tile-1-picture-background-with-ratio {
	.ratio(0.6910229645093946);
	background-position: center center;
	background-size: cover;
}

.field-trips-at-quarry-hill-tile-2-picture img {
	width: 479px;
}

.ready-to-load-img .field-trips-at-quarry-hill-tile-2-picture-background, .ready-to-load-img .field-trips-at-quarry-hill-tile-2-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/field-trips-at-quarry-hill-tile-2.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/field-trips-at-quarry-hill-tile-2.webp");
	}
	@media (max-width: 239.5px) {

		background-image: url("@{cdn3}/img/field-trips-at-quarry-hill-tile-2-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/field-trips-at-quarry-hill-tile-2-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/field-trips-at-quarry-hill-tile-2-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/field-trips-at-quarry-hill-tile-2-2x.webp");
		}
		
		@media (max-width: 239.5px) {

			background-image: url("@{cdn3}/img/field-trips-at-quarry-hill-tile-2.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/field-trips-at-quarry-hill-tile-2.webp");
			}
		}
	}
}

.field-trips-at-quarry-hill-tile-2-picture-background-ratio, .field-trips-at-quarry-hill-tile-2-picture-background-with-ratio {
	.ratio(0.6910229645093946);
	background-position: center center;
	background-size: cover;
}

.field-trips-at-quarry-hill-tile-3-picture img {
	width: 478px;
}

.ready-to-load-img .field-trips-at-quarry-hill-tile-3-picture-background, .ready-to-load-img .field-trips-at-quarry-hill-tile-3-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/field-trips-at-quarry-hill-tile-3.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/field-trips-at-quarry-hill-tile-3.webp");
	}
	@media (max-width: 239px) {

		background-image: url("@{cdn0}/img/field-trips-at-quarry-hill-tile-3-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/field-trips-at-quarry-hill-tile-3-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/field-trips-at-quarry-hill-tile-3-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/field-trips-at-quarry-hill-tile-3-2x.webp");
		}
		
		@media (max-width: 239px) {

			background-image: url("@{cdn0}/img/field-trips-at-quarry-hill-tile-3.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/field-trips-at-quarry-hill-tile-3.webp");
			}
		}
	}
}

.field-trips-at-quarry-hill-tile-3-picture-background-ratio, .field-trips-at-quarry-hill-tile-3-picture-background-with-ratio {
	.ratio(0.6924686192468619);
	background-position: center center;
	background-size: cover;
}

.field-trips-at-quarry-hill-tile-4-picture img {
	width: 480px;
}

.ready-to-load-img .field-trips-at-quarry-hill-tile-4-picture-background, .ready-to-load-img .field-trips-at-quarry-hill-tile-4-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/field-trips-at-quarry-hill-tile-4.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/field-trips-at-quarry-hill-tile-4.webp");
	}
	@media (max-width: 240px) {

		background-image: url("@{cdn1}/img/field-trips-at-quarry-hill-tile-4-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/field-trips-at-quarry-hill-tile-4-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/field-trips-at-quarry-hill-tile-4-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/field-trips-at-quarry-hill-tile-4-2x.webp");
		}
		
		@media (max-width: 240px) {

			background-image: url("@{cdn1}/img/field-trips-at-quarry-hill-tile-4.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/field-trips-at-quarry-hill-tile-4.webp");
			}
		}
	}
}

.field-trips-at-quarry-hill-tile-4-picture-background-ratio, .field-trips-at-quarry-hill-tile-4-picture-background-with-ratio {
	.ratio(0.6895833333333333);
	background-position: center center;
	background-size: cover;
}

.footer-leaf-cluster-picture img {
	width: 199px;
}

.ready-to-load-img .footer-leaf-cluster-picture-background, .ready-to-load-img .footer-leaf-cluster-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/footer-leaf-cluster.png");
	
	.webp & {
		background-image: url("@{cdn2}/img/footer-leaf-cluster.webp");
	}
	@media (max-width: 99.5px) {

		background-image: url("@{cdn2}/img/footer-leaf-cluster-half.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/footer-leaf-cluster-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/footer-leaf-cluster-2x.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/footer-leaf-cluster-2x.webp");
		}
		
		@media (max-width: 99.5px) {

			background-image: url("@{cdn2}/img/footer-leaf-cluster.png");
			
			.webp & {
				background-image: url("@{cdn2}/img/footer-leaf-cluster.webp");
			}
		}
	}
}

.footer-leaf-cluster-picture-background-ratio, .footer-leaf-cluster-picture-background-with-ratio {
	.ratio(0.7135678391959799);
	background-position: center center;
	background-size: cover;
}

.home-banner-1-picture img {
	width: 1920px;
}

.ready-to-load-img .home-banner-1-picture-background, .ready-to-load-img .home-banner-1-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/home-banner-1.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/home-banner-1.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn3}/img/home-banner-1-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/home-banner-1-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/home-banner-1-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/home-banner-1-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn3}/img/home-banner-1.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/home-banner-1.webp");
			}
		}
	}
}

.home-banner-1-picture-background-ratio, .home-banner-1-picture-background-with-ratio {
	.ratio(0.3640625);
	background-position: center center;
	background-size: cover;
}

.home-footer-tile-1-picture img {
	width: 480px;
}

.ready-to-load-img .home-footer-tile-1-picture-background, .ready-to-load-img .home-footer-tile-1-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/home-footer-tile-1.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/home-footer-tile-1.webp");
	}
	@media (max-width: 240px) {

		background-image: url("@{cdn0}/img/home-footer-tile-1-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/home-footer-tile-1-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/home-footer-tile-1-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/home-footer-tile-1-2x.webp");
		}
		
		@media (max-width: 240px) {

			background-image: url("@{cdn0}/img/home-footer-tile-1.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/home-footer-tile-1.webp");
			}
		}
	}
}

.home-footer-tile-1-picture-background-ratio, .home-footer-tile-1-picture-background-with-ratio {
	.ratio(0.6875);
	background-position: center center;
	background-size: cover;
}

.home-footer-tile-2-picture img {
	width: 479.5px;
}

.ready-to-load-img .home-footer-tile-2-picture-background, .ready-to-load-img .home-footer-tile-2-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/home-footer-tile-2.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/home-footer-tile-2.webp");
	}
	@media (max-width: 239.75px) {

		background-image: url("@{cdn1}/img/home-footer-tile-2-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/home-footer-tile-2-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/home-footer-tile-2-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/home-footer-tile-2-2x.webp");
		}
		
		@media (max-width: 239.75px) {

			background-image: url("@{cdn1}/img/home-footer-tile-2.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/home-footer-tile-2.webp");
			}
		}
	}
}

.home-footer-tile-2-picture-background-ratio, .home-footer-tile-2-picture-background-with-ratio {
	.ratio(0.6882168925964547);
	background-position: center center;
	background-size: cover;
}

.home-footer-tile-3-picture img {
	width: 480px;
}

.ready-to-load-img .home-footer-tile-3-picture-background, .ready-to-load-img .home-footer-tile-3-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/home-footer-tile-3.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/home-footer-tile-3.webp");
	}
	@media (max-width: 240px) {

		background-image: url("@{cdn2}/img/home-footer-tile-3-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/home-footer-tile-3-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/home-footer-tile-3-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/home-footer-tile-3-2x.webp");
		}
		
		@media (max-width: 240px) {

			background-image: url("@{cdn2}/img/home-footer-tile-3.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/home-footer-tile-3.webp");
			}
		}
	}
}

.home-footer-tile-3-picture-background-ratio, .home-footer-tile-3-picture-background-with-ratio {
	.ratio(0.6875);
	background-position: center center;
	background-size: cover;
}

.home-footer-tile-4-picture img {
	width: 479px;
}

.ready-to-load-img .home-footer-tile-4-picture-background, .ready-to-load-img .home-footer-tile-4-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/home-footer-tile-4.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/home-footer-tile-4.webp");
	}
	@media (max-width: 239.5px) {

		background-image: url("@{cdn3}/img/home-footer-tile-4-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/home-footer-tile-4-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/home-footer-tile-4-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/home-footer-tile-4-2x.webp");
		}
		
		@media (max-width: 239.5px) {

			background-image: url("@{cdn3}/img/home-footer-tile-4.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/home-footer-tile-4.webp");
			}
		}
	}
}

.home-footer-tile-4-picture-background-ratio, .home-footer-tile-4-picture-background-with-ratio {
	.ratio(0.6889352818371608);
	background-position: center center;
	background-size: cover;
}

.instagram-logo-picture img {
	width: 29px;
}

.ready-to-load-img .instagram-logo-picture-background, .ready-to-load-img .instagram-logo-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/instagram-logo.png");
	
	.webp & {
		background-image: url("@{cdn0}/img/instagram-logo.webp");
	}
	@media (max-width: 14.5px) {

		background-image: url("@{cdn0}/img/instagram-logo-half.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/instagram-logo-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/instagram-logo-2x.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/instagram-logo-2x.webp");
		}
		
		@media (max-width: 14.5px) {

			background-image: url("@{cdn0}/img/instagram-logo.png");
			
			.webp & {
				background-image: url("@{cdn0}/img/instagram-logo.webp");
			}
		}
	}
}

.instagram-logo-picture-background-ratio, .instagram-logo-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.location-circle-picture img {
	width: 45px;
}

.ready-to-load-img .location-circle-picture-background, .ready-to-load-img .location-circle-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/location-circle.png");
	
	.webp & {
		background-image: url("@{cdn1}/img/location-circle.webp");
	}
	@media (max-width: 22.5px) {

		background-image: url("@{cdn1}/img/location-circle-half.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/location-circle-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/location-circle-2x.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/location-circle-2x.webp");
		}
		
		@media (max-width: 22.5px) {

			background-image: url("@{cdn1}/img/location-circle.png");
			
			.webp & {
				background-image: url("@{cdn1}/img/location-circle.webp");
			}
		}
	}
}

.location-circle-picture-background-ratio, .location-circle-picture-background-with-ratio {
	.ratio(0.9777777777777777);
	background-position: center center;
	background-size: cover;
}

.location-map-picture img {
	width: 1920px;
}

.ready-to-load-img .location-map-picture-background, .ready-to-load-img .location-map-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/location-map.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/location-map.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn2}/img/location-map-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/location-map-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/location-map-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/location-map-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn2}/img/location-map.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/location-map.webp");
			}
		}
	}
}

.location-map-picture-background-ratio, .location-map-picture-background-with-ratio {
	.ratio(0.25833333333333336);
	background-position: center center;
	background-size: cover;
}

.nature-center-hall-picture img {
	width: 873px;
}

.ready-to-load-img .nature-center-hall-picture-background, .ready-to-load-img .nature-center-hall-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/nature-center-hall.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/nature-center-hall.webp");
	}
	@media (max-width: 436.5px) {

		background-image: url("@{cdn3}/img/nature-center-hall-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/nature-center-hall-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/nature-center-hall-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/nature-center-hall-2x.webp");
		}
		
		@media (max-width: 436.5px) {

			background-image: url("@{cdn3}/img/nature-center-hall.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/nature-center-hall.webp");
			}
		}
	}
}

.nature-center-hall-picture-background-ratio, .nature-center-hall-picture-background-with-ratio {
	.ratio(0.41809851088201605);
	background-position: center center;
	background-size: cover;
}

.program-groups-tile-1-picture img {
	width: 479.5px;
}

.ready-to-load-img .program-groups-tile-1-picture-background, .ready-to-load-img .program-groups-tile-1-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/program-groups-tile-1.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/program-groups-tile-1.webp");
	}
	@media (max-width: 239.75px) {

		background-image: url("@{cdn0}/img/program-groups-tile-1-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/program-groups-tile-1-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/program-groups-tile-1-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/program-groups-tile-1-2x.webp");
		}
		
		@media (max-width: 239.75px) {

			background-image: url("@{cdn0}/img/program-groups-tile-1.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/program-groups-tile-1.webp");
			}
		}
	}
}

.program-groups-tile-1-picture-background-ratio, .program-groups-tile-1-picture-background-with-ratio {
	.ratio(0.6903023983315955);
	background-position: center center;
	background-size: cover;
}

.program-groups-tile-2-picture img {
	width: 479.5px;
}

.ready-to-load-img .program-groups-tile-2-picture-background, .ready-to-load-img .program-groups-tile-2-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/program-groups-tile-2.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/program-groups-tile-2.webp");
	}
	@media (max-width: 239.75px) {

		background-image: url("@{cdn1}/img/program-groups-tile-2-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/program-groups-tile-2-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/program-groups-tile-2-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/program-groups-tile-2-2x.webp");
		}
		
		@media (max-width: 239.75px) {

			background-image: url("@{cdn1}/img/program-groups-tile-2.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/program-groups-tile-2.webp");
			}
		}
	}
}

.program-groups-tile-2-picture-background-ratio, .program-groups-tile-2-picture-background-with-ratio {
	.ratio(0.6903023983315955);
	background-position: center center;
	background-size: cover;
}

.program-groups-tile-3-picture img {
	width: 481px;
}

.ready-to-load-img .program-groups-tile-3-picture-background, .ready-to-load-img .program-groups-tile-3-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/program-groups-tile-3.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/program-groups-tile-3.webp");
	}
	@media (max-width: 240.5px) {

		background-image: url("@{cdn2}/img/program-groups-tile-3-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/program-groups-tile-3-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/program-groups-tile-3-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/program-groups-tile-3-2x.webp");
		}
		
		@media (max-width: 240.5px) {

			background-image: url("@{cdn2}/img/program-groups-tile-3.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/program-groups-tile-3.webp");
			}
		}
	}
}

.program-groups-tile-3-picture-background-ratio, .program-groups-tile-3-picture-background-with-ratio {
	.ratio(0.6881496881496881);
	background-position: center center;
	background-size: cover;
}

.program-groups-tile-4-picture img {
	width: 478.5px;
}

.ready-to-load-img .program-groups-tile-4-picture-background, .ready-to-load-img .program-groups-tile-4-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/program-groups-tile-4.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/program-groups-tile-4.webp");
	}
	@media (max-width: 239.25px) {

		background-image: url("@{cdn3}/img/program-groups-tile-4-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/program-groups-tile-4-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/program-groups-tile-4-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/program-groups-tile-4-2x.webp");
		}
		
		@media (max-width: 239.25px) {

			background-image: url("@{cdn3}/img/program-groups-tile-4.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/program-groups-tile-4.webp");
			}
		}
	}
}

.program-groups-tile-4-picture-background-ratio, .program-groups-tile-4-picture-background-with-ratio {
	.ratio(0.6917450365726228);
	background-position: center center;
	background-size: cover;
}

.qh-logo-picture img {
	width: 104px;
}

.ready-to-load-img .qh-logo-picture-background, .ready-to-load-img .qh-logo-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/qh-logo.png");
	
	.webp & {
		background-image: url("@{cdn0}/img/qh-logo.webp");
	}
	@media (max-width: 52px) {

		background-image: url("@{cdn0}/img/qh-logo-half.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/qh-logo-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/qh-logo-2x.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/qh-logo-2x.webp");
		}
		
		@media (max-width: 52px) {

			background-image: url("@{cdn0}/img/qh-logo.png");
			
			.webp & {
				background-image: url("@{cdn0}/img/qh-logo.webp");
			}
		}
	}
}

.qh-logo-picture-background-ratio, .qh-logo-picture-background-with-ratio {
	.ratio(1.0192307692307692);
	background-position: center center;
	background-size: cover;
}

.qhnc-wood-cookie-logo-picture img {
	width: 108px;
}

.ready-to-load-img .qhnc-wood-cookie-logo-picture-background, .ready-to-load-img .qhnc-wood-cookie-logo-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/qhnc-wood-cookie-logo.png");
	
	.webp & {
		background-image: url("@{cdn1}/img/qhnc-wood-cookie-logo.webp");
	}
	@media (max-width: 54px) {

		background-image: url("@{cdn1}/img/qhnc-wood-cookie-logo-half.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/qhnc-wood-cookie-logo-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/qhnc-wood-cookie-logo-2x.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/qhnc-wood-cookie-logo-2x.webp");
		}
		
		@media (max-width: 54px) {

			background-image: url("@{cdn1}/img/qhnc-wood-cookie-logo.png");
			
			.webp & {
				background-image: url("@{cdn1}/img/qhnc-wood-cookie-logo.webp");
			}
		}
	}
}

.qhnc-wood-cookie-logo-picture-background-ratio, .qhnc-wood-cookie-logo-picture-background-with-ratio {
	.ratio(1.0092592592592593);
	background-position: center center;
	background-size: cover;
}

.register-footer-tile-1-picture img {
	width: 479.5px;
}

.ready-to-load-img .register-footer-tile-1-picture-background, .ready-to-load-img .register-footer-tile-1-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/register-footer-tile-1.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/register-footer-tile-1.webp");
	}
	@media (max-width: 239.75px) {

		background-image: url("@{cdn2}/img/register-footer-tile-1-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/register-footer-tile-1-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/register-footer-tile-1-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/register-footer-tile-1-2x.webp");
		}
		
		@media (max-width: 239.75px) {

			background-image: url("@{cdn2}/img/register-footer-tile-1.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/register-footer-tile-1.webp");
			}
		}
	}
}

.register-footer-tile-1-picture-background-ratio, .register-footer-tile-1-picture-background-with-ratio {
	.ratio(0.6882168925964547);
	background-position: center center;
	background-size: cover;
}

.register-footer-tile-2-picture img {
	width: 479.5px;
}

.ready-to-load-img .register-footer-tile-2-picture-background, .ready-to-load-img .register-footer-tile-2-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/register-footer-tile-2.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/register-footer-tile-2.webp");
	}
	@media (max-width: 239.75px) {

		background-image: url("@{cdn3}/img/register-footer-tile-2-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/register-footer-tile-2-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/register-footer-tile-2-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/register-footer-tile-2-2x.webp");
		}
		
		@media (max-width: 239.75px) {

			background-image: url("@{cdn3}/img/register-footer-tile-2.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/register-footer-tile-2.webp");
			}
		}
	}
}

.register-footer-tile-2-picture-background-ratio, .register-footer-tile-2-picture-background-with-ratio {
	.ratio(0.6882168925964547);
	background-position: center center;
	background-size: cover;
}

.register-footer-tile-3-picture img {
	width: 480px;
}

.ready-to-load-img .register-footer-tile-3-picture-background, .ready-to-load-img .register-footer-tile-3-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/register-footer-tile-3.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/register-footer-tile-3.webp");
	}
	@media (max-width: 240px) {

		background-image: url("@{cdn0}/img/register-footer-tile-3-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/register-footer-tile-3-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/register-footer-tile-3-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/register-footer-tile-3-2x.webp");
		}
		
		@media (max-width: 240px) {

			background-image: url("@{cdn0}/img/register-footer-tile-3.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/register-footer-tile-3.webp");
			}
		}
	}
}

.register-footer-tile-3-picture-background-ratio, .register-footer-tile-3-picture-background-with-ratio {
	.ratio(0.6875);
	background-position: center center;
	background-size: cover;
}

.register-footer-tile-4-picture img {
	width: 478.5px;
}

.ready-to-load-img .register-footer-tile-4-picture-background, .ready-to-load-img .register-footer-tile-4-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/register-footer-tile-4.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/register-footer-tile-4.webp");
	}
	@media (max-width: 239.25px) {

		background-image: url("@{cdn1}/img/register-footer-tile-4-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/register-footer-tile-4-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/register-footer-tile-4-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/register-footer-tile-4-2x.webp");
		}
		
		@media (max-width: 239.25px) {

			background-image: url("@{cdn1}/img/register-footer-tile-4.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/register-footer-tile-4.webp");
			}
		}
	}
}

.register-footer-tile-4-picture-background-ratio, .register-footer-tile-4-picture-background-with-ratio {
	.ratio(0.6896551724137931);
	background-position: center center;
	background-size: cover;
}

.ring-pattern-left-picture img {
	width: 528px;
}

.ready-to-load-img .ring-pattern-left-picture-background, .ready-to-load-img .ring-pattern-left-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/ring-pattern-left.png");
	
	.webp & {
		background-image: url("@{cdn2}/img/ring-pattern-left.webp");
	}
	@media (max-width: 264px) {

		background-image: url("@{cdn2}/img/ring-pattern-left-half.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/ring-pattern-left-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/ring-pattern-left-2x.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/ring-pattern-left-2x.webp");
		}
		
		@media (max-width: 264px) {

			background-image: url("@{cdn2}/img/ring-pattern-left.png");
			
			.webp & {
				background-image: url("@{cdn2}/img/ring-pattern-left.webp");
			}
		}
	}
}

.ring-pattern-left-picture-background-ratio, .ring-pattern-left-picture-background-with-ratio {
	.ratio(6.295454545454546);
	background-position: center center;
	background-size: cover;
}

.ring-pattern-right-picture img {
	width: 514px;
}

.ready-to-load-img .ring-pattern-right-picture-background, .ready-to-load-img .ring-pattern-right-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/ring-pattern-right.png");
	
	.webp & {
		background-image: url("@{cdn3}/img/ring-pattern-right.webp");
	}
	@media (max-width: 257px) {

		background-image: url("@{cdn3}/img/ring-pattern-right-half.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/ring-pattern-right-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/ring-pattern-right-2x.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/ring-pattern-right-2x.webp");
		}
		
		@media (max-width: 257px) {

			background-image: url("@{cdn3}/img/ring-pattern-right.png");
			
			.webp & {
				background-image: url("@{cdn3}/img/ring-pattern-right.webp");
			}
		}
	}
}

.ring-pattern-right-picture-background-ratio, .ring-pattern-right-picture-background-with-ratio {
	.ratio(6.466926070038911);
	background-position: center center;
	background-size: cover;
}

.stone-pocked-pattern-picture img {
	width: 1920px;
}

.ready-to-load-img .stone-pocked-pattern-picture-background, .ready-to-load-img .stone-pocked-pattern-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/stone-pocked-pattern.png");
	
	.webp & {
		background-image: url("@{cdn0}/img/stone-pocked-pattern.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn0}/img/stone-pocked-pattern-half.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/stone-pocked-pattern-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/stone-pocked-pattern-2x.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/stone-pocked-pattern-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn0}/img/stone-pocked-pattern.png");
			
			.webp & {
				background-image: url("@{cdn0}/img/stone-pocked-pattern.webp");
			}
		}
	}
}

.stone-pocked-pattern-picture-background-ratio, .stone-pocked-pattern-picture-background-with-ratio {
	.ratio(0.5869791666666667);
	background-position: center center;
	background-size: cover;
}

.summer-nature-camp-tile-1-picture img {
	width: 478.5px;
}

.ready-to-load-img .summer-nature-camp-tile-1-picture-background, .ready-to-load-img .summer-nature-camp-tile-1-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/summer-nature-camp-tile-1.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/summer-nature-camp-tile-1.webp");
	}
	@media (max-width: 239.25px) {

		background-image: url("@{cdn1}/img/summer-nature-camp-tile-1-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/summer-nature-camp-tile-1-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/summer-nature-camp-tile-1-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/summer-nature-camp-tile-1-2x.webp");
		}
		
		@media (max-width: 239.25px) {

			background-image: url("@{cdn1}/img/summer-nature-camp-tile-1.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/summer-nature-camp-tile-1.webp");
			}
		}
	}
}

.summer-nature-camp-tile-1-picture-background-ratio, .summer-nature-camp-tile-1-picture-background-with-ratio {
	.ratio(0.6917450365726228);
	background-position: center center;
	background-size: cover;
}

.summer-nature-camp-tile-2-picture img {
	width: 479px;
}

.ready-to-load-img .summer-nature-camp-tile-2-picture-background, .ready-to-load-img .summer-nature-camp-tile-2-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/summer-nature-camp-tile-2.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/summer-nature-camp-tile-2.webp");
	}
	@media (max-width: 239.5px) {

		background-image: url("@{cdn2}/img/summer-nature-camp-tile-2-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/summer-nature-camp-tile-2-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/summer-nature-camp-tile-2-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/summer-nature-camp-tile-2-2x.webp");
		}
		
		@media (max-width: 239.5px) {

			background-image: url("@{cdn2}/img/summer-nature-camp-tile-2.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/summer-nature-camp-tile-2.webp");
			}
		}
	}
}

.summer-nature-camp-tile-2-picture-background-ratio, .summer-nature-camp-tile-2-picture-background-with-ratio {
	.ratio(0.6910229645093946);
	background-position: center center;
	background-size: cover;
}

.summer-nature-camp-tile-3-picture img {
	width: 480px;
}

.ready-to-load-img .summer-nature-camp-tile-3-picture-background, .ready-to-load-img .summer-nature-camp-tile-3-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/summer-nature-camp-tile-3.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/summer-nature-camp-tile-3.webp");
	}
	@media (max-width: 240px) {

		background-image: url("@{cdn3}/img/summer-nature-camp-tile-3-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/summer-nature-camp-tile-3-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/summer-nature-camp-tile-3-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/summer-nature-camp-tile-3-2x.webp");
		}
		
		@media (max-width: 240px) {

			background-image: url("@{cdn3}/img/summer-nature-camp-tile-3.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/summer-nature-camp-tile-3.webp");
			}
		}
	}
}

.summer-nature-camp-tile-3-picture-background-ratio, .summer-nature-camp-tile-3-picture-background-with-ratio {
	.ratio(0.6895833333333333);
	background-position: center center;
	background-size: cover;
}

.summer-nature-camp-tile-4-picture img {
	width: 479.5px;
}

.ready-to-load-img .summer-nature-camp-tile-4-picture-background, .ready-to-load-img .summer-nature-camp-tile-4-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/summer-nature-camp-tile-4.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/summer-nature-camp-tile-4.webp");
	}
	@media (max-width: 239.75px) {

		background-image: url("@{cdn0}/img/summer-nature-camp-tile-4-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/summer-nature-camp-tile-4-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/summer-nature-camp-tile-4-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/summer-nature-camp-tile-4-2x.webp");
		}
		
		@media (max-width: 239.75px) {

			background-image: url("@{cdn0}/img/summer-nature-camp-tile-4.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/summer-nature-camp-tile-4.webp");
			}
		}
	}
}

.summer-nature-camp-tile-4-picture-background-ratio, .summer-nature-camp-tile-4-picture-background-with-ratio {
	.ratio(0.6903023983315955);
	background-position: center center;
	background-size: cover;
}

.yellow-curve-picture img {
	width: 200.5px;
}

.ready-to-load-img .yellow-curve-picture-background, .ready-to-load-img .yellow-curve-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/yellow-curve.png");
	
	.webp & {
		background-image: url("@{cdn1}/img/yellow-curve.webp");
	}
	@media (max-width: 100.25px) {

		background-image: url("@{cdn1}/img/yellow-curve-half.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/yellow-curve-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/yellow-curve-2x.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/yellow-curve-2x.webp");
		}
		
		@media (max-width: 100.25px) {

			background-image: url("@{cdn1}/img/yellow-curve.png");
			
			.webp & {
				background-image: url("@{cdn1}/img/yellow-curve.webp");
			}
		}
	}
}

.yellow-curve-picture-background-ratio, .yellow-curve-picture-background-with-ratio {
	.ratio(1.9950124688279303);
	background-position: center center;
	background-size: cover;
}

.youtube-logo-picture img {
	width: 33px;
}

.ready-to-load-img .youtube-logo-picture-background, .ready-to-load-img .youtube-logo-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/youtube-logo.png");
	
	.webp & {
		background-image: url("@{cdn2}/img/youtube-logo.webp");
	}
	@media (max-width: 16.5px) {

		background-image: url("@{cdn2}/img/youtube-logo-half.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/youtube-logo-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/youtube-logo-2x.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/youtube-logo-2x.webp");
		}
		
		@media (max-width: 16.5px) {

			background-image: url("@{cdn2}/img/youtube-logo.png");
			
			.webp & {
				background-image: url("@{cdn2}/img/youtube-logo.webp");
			}
		}
	}
}

.youtube-logo-picture-background-ratio, .youtube-logo-picture-background-with-ratio {
	.ratio(0.696969696969697);
	background-position: center center;
	background-size: cover;
}
